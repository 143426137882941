<template>
  <!--START PAGE HEADER -->
  <header class="page-header">
    <div class="d-flex align-items-center">
      <div class="mr-auto">
        <h1>{{title}}</h1>
      </div>
      <ul class="actions top-right">
        <slot name="actions"/>
      </ul>
    </div>
    <slot />
  </header>
  <!--END PAGE HEADER -->
</template>

<script>
export default {
  name: 'PageHeader',
  props: ['title'],
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
