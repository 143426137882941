<template>
  <div class="container">
    <div class="content">
      <PageHeader title="403 Sem permissão" />
      <div class="page-content container-fluid">
        <p>Você não tem permissão para acessar isso.</p>
        <p><router-link to="/"> Dashboard </router-link></p>
        <p><a href="https://yup.chat/" target="_blank"> Website </a></p>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue';

export default {
  name: '403',
  components: {
    PageHeader,
  },
};
</script>

<style scoped lang="scss">
</style>
